import * as d3 from 'd3';
import { useRef, useEffect, useState } from 'react';

import { getWeightUnit } from '../../config/commons';
import { getHeight, getWidth, handleResizeEvent } from '../../utils/dimensions';

import styles from './Chart.module.scss';

const pallete = ['#FF1493', '#E67A00', '#2B9A21', '#00BFFC', '#1B62E6'];
const minTextAnchor = 136;

interface Props {
  weightLabels: string[];
  data: number[];
  percents: string[];
  measureUnit: string;
  averageWeight?: number;
  axisClassName?: string;
  lineTop?: string;
  textClassName?: string;
  textInsideBarClassName?: string;
  containerClassName?: string;
  colorBars?: string;
  typeStyles: 'DEFAULT' | 'PDF';
  isConsolidatedGrowOut: boolean;
}

export default function BarsChart (props: Props) {
  const {
    data,
    weightLabels,
    measureUnit,
    percents = [],
    colorBars,
    axisClassName = styles.axis,
    lineTop = styles.lineTop,
    textClassName = styles.chartText,
    textInsideBarClassName = styles.chartText,
    containerClassName = styles.container,
    averageWeight = 0,
    typeStyles = 'DEFAULT',
    isConsolidatedGrowOut,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const weightsUnit = getWeightUnit(averageWeight);

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const [heightScreen, setHeightScreen] = useState(window.innerHeight);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidthScreen(window.innerWidth);
      setHeightScreen(window.innerHeight);
    });
  }, []);

  useEffect(updateScreenOnResize, [widthScreen, heightScreen, data, weightLabels, measureUnit, percents]);

  function updateScreenOnResize () {
    const margin = { top: 0, right: 20, bottom: 25, left: 15 };

    const width = getWidth(ref) - margin.left - margin.right;
    const height = getHeight(ref) - margin.top - margin.bottom;

    const chartData = [];
    if (isConsolidatedGrowOut) {
      for (let index = 0; index < data.length; index++) {
        const color = typeStyles === 'PDF' ? '#2668AD' : colorBars || pallete[index];

        const d = { label: weightLabels[index], value: data[index], color };
        chartData.push(d);
      }
    } else {
      for (let index = 0; index < data.length; index++) {
        const d = { label: weightLabels[index], value: data[index], color: colorBars || pallete[index] };
        chartData.push(d);
      }
    }

    d3.select(ref.current).select('svg').remove();

    const svg = d3.select(ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const x = d3.scaleLinear()
      .domain([0, d3.max(chartData, d => d.value) as number])
      .range([0, width]);

    const xAxis = d3.axisBottom(x).ticks(4).tickSize(-height);
    svg.append('g')
      .attr('class', axisClassName)
      .attr('transform', `translate(0,${height})`)
      .call(xAxis);

    const y = d3.scaleBand()
      .range([0, height])
      .domain(chartData.map(function (d) { return d.label; }))
      .padding(0.15);

    const yAxis = d3.axisLeft(y).tickSize(0).tickFormat(() => { return ''; });
    svg.append('g')
      .attr('class', axisClassName)
      .call(yAxis);

    const bandWidth = y.bandwidth();
    const heightRect = isConsolidatedGrowOut ? bandWidth - (bandWidth > 20 ? 20 : 0) : bandWidth - (bandWidth > 25 ? 25 : 0);

    svg.selectAll('rect')
      .data(chartData)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', d => y(d.label) as number + 20)
      .attr('width', d => x(d.value))
      .attr('height', heightRect)
      .attr('fill', d => d.color);

    if (!(isConsolidatedGrowOut)) {
      svg.selectAll('.label')
        .data(chartData)
        .enter()
        .append('text')
        .text(d => `${d.label} ${weightsUnit}`)
        .classed(textClassName, true)
        .attr('fill', 'white')
        .attr('x', 6)
        .attr('y', d => y(d.label) as number + 15);
    }

    svg.append('g')
      .attr('text-anchor', 'end')
      .selectAll('text')
      .data(chartData)
      .join('text')
      .attr('class', function (d) {
        if (x(d.value) < minTextAnchor) {
          return textClassName;
        }
        return textInsideBarClassName;
      })
      .attr('x', d => x(d.value))
      .attr('y', (d) => {
        if (isConsolidatedGrowOut) {
          return y(d.label) as number + (y.bandwidth() / 2) + 8;
        }
        return y(d.label) as number + (y.bandwidth() / 2) + 6;
      })
      .attr('dy', '0.5em')
      .attr('dx', -5)
      .html((d, index) => {
        if (isConsolidatedGrowOut) {
          if (x(d.value) >= minTextAnchor) {
            return `${percents[index]}% - ${d.value} ${measureUnit}`;
          }
          return `${d.label} → ${percents[index]}% - ${d.value} ${measureUnit}`;
        }
        return `${percents[index]}% - ${d.value} ${measureUnit}`;
      })
      .call(function (text) {
        return text.filter(function (d) {
          return x(d.value) < minTextAnchor;
        }) // short bars
          .attr('dx', 5)
          .attr('text-anchor', 'start');
      });

    if (isConsolidatedGrowOut) {
      svg.append('g')
        .attr('text-anchor', 'end')
        .selectAll('text')
        .data(chartData)
        .join('text')
        .attr('class', function (d) {
          if (x(d.value) < minTextAnchor) {
            return textClassName;
          }
          return textInsideBarClassName;
        })
        .attr('x', 47)
        .attr('y', (d) => {
          return y(d.label) as number + (y.bandwidth() / 2) + 8;
        })
        .attr('dy', '0.5em')
        .attr('dx', -5)
        .html((d) => {
          if (x(d.value) >= minTextAnchor) {
            return d.label;
          }
          return null;
        });
    }

    svg.append('line')
      .attr('class', lineTop)
      .attr('x1', 0.5)
      .attr('y1', 0.5)
      .attr('x2', width)
      .attr('y2', 0.5);
  }

  return (
    <div className={containerClassName}>
      <div ref={ref} />
    </div>
  );
}
