import axios, { AxiosInstance } from 'axios';

export class ApiClient {
  axios: AxiosInstance;

  // eslint-disable-next-line
  constructor() {
    this.axios = axios.create();
  }
}

const apiClient: ApiClient = new ApiClient();
export const axiosClient = apiClient.axios;