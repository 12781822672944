import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';

import './i18n';
import './index.scss';
import App from './App';
import { store } from './state/store';
import { SENTRY_DSN } from './config/sentry';
import { environments } from './config/commons';
import * as serviceWorker from './serviceWorker';
import { ENVIRONMENT } from './config/config.api';

if (ENVIRONMENT === environments.PROD) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: process.env.COMMIT_REF,
    integrations: [
      new RewriteFrames()
    ],
    tracesSampleRate: 1.0,
    environment: ENVIRONMENT
  });
}


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
