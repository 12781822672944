import { ReactNode } from 'react';
import styles from './Content.module.scss';
import cx from 'classnames';

export default function Content (props: {
  children: ReactNode;
  title?: string;
  headerClassName?: string;
  titleClassName?: string;
  subtitle?: ReactNode;
  actions?: ReactNode;
  className?: string;
  noPadding?: boolean;
  style?: any;
  contentId?: string;
}) {
  const { children, className, title, subtitle, actions, noPadding, style, headerClassName, titleClassName, contentId } = props;

  return <div className={cx(styles.content, className)} style={style} id={contentId}>
    <div className={cx(styles.header, headerClassName ?? '')}>
      <div className={cx(styles.title, titleClassName ?? '')}>{title}</div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    <div className={cx(styles.body, noPadding ? styles.noPadding : '')}>
      {children}
    </div>

  </div>;
}
