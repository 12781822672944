import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';

import detailAnalysisReducer from '../pages/Analysis-Detail/detailAnalysisSlice';

export const store = configureStore({
  middleware: [...getDefaultMiddleware()],
  reducer: {
    detail: detailAnalysisReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
