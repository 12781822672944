import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';

import { typeHistogram } from '../../config/commons';

import styles from './Histogram.module.scss';
import { Dataset, Frequency } from './interfaces';
import { colosPigmentation, typeStyles } from './histogram.helpers';

export default class HistogramD3 {
  container: any;
  svg: any;

  x: any;
  y: any;
  xScaleLinear: any;

  bars: any;
  textLimits: any;
  ticksLimits: any;
  textFrequencies: any;

  timeTransition = 300;

  width = 0;
  height = 0
  margin = { top: 35, right: 20, bottom: 25, left: 20 };

  dataset: Dataset[];
  histogram: string;
  limits: number[];
  frequency: Frequency;
  average: number;
  unit?: string;

  //styles
  typeStyles = '';
  averageTextStyle = '';
  limitsStyle = '';
  ticksLimitsStyle = '';
  frequenciesStyles = '';
  xAxisPadding = 0.01;
  fillBars = '';

  // eslint-disable-next-line
  constructor(
    container: HTMLDivElement | null,
    dataset: Dataset[],
    histogram: string,
    limits: number[],
    frequency: Frequency,
    average: number,
    width: number,
    height: number,
    typeStyles: string,
    unit?: string,
  ) {
    this.container = container;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.dataset = dataset;
    this.histogram = histogram;
    this.limits = limits;
    this.frequency = frequency;
    this.average = average;
    this.unit = unit;

    this.typeStyles = typeStyles;

    this.updateStyles();
    this.createSvgElement(this.width, this.height);
    this.createHistogram(dataset, limits, frequency, average);
  }

  updateStyles () {
    if (this.typeStyles === typeStyles.DEFAULT) {
      this.xAxisPadding = 0.01;
      this.fillBars = '#696ffb';
      this.averageTextStyle = styles.averageTextDefault;
      this.limitsStyle = styles.limitsDefault;
      this.ticksLimitsStyle = styles.ticksLimitsDefault;
      this.frequenciesStyles = styles.frequenciesDefault;
    } else if (this.typeStyles === typeStyles.PDF) {
      this.xAxisPadding = 0.05;
      this.fillBars = '#2668AD';
      this.averageTextStyle = styles.averageTextPdf;
      this.limitsStyle = styles.limitsPdf;
      this.ticksLimitsStyle = styles.ticksLimitsPdf;
      this.frequenciesStyles = styles.frequenciesPdf;
    }
  }

  createSvgElement (width: number, height: number) {
    const { container, margin } = this;
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('id', 'content')
      .append('g')
      .attr('transform', `translate( ${margin.left} , ${margin.top} )`);
  }

  createHistogram (dataset: Dataset[], limits: number[], frequency: Frequency, average: number) {
    const { svg, height } = this;

    this.buildAxisX();
    this.buildAxisY();

    this.bars = svg.selectAll('.histogram')
      .data(dataset)
      .enter()
      .append('rect')
      .attr('class', 'histogram')
      .attr('x', (d: Dataset) => this.x(d.index.toString()) as number)
      .attr('width', this.x.bandwidth())
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', (d: Dataset, index: number) => {
        if (this.histogram === typeHistogram.PIGMENTATION) {
          return colosPigmentation[index];
        }
        return this.fillBars;
      });


    const tickPositionY = this.typeStyles === typeStyles.DEFAULT ? 8 : 7;
    const tickExtraPaddingAxisX = this.typeStyles === typeStyles.DEFAULT ? 0.5 : 2.5;
    this.ticksLimits = svg.selectAll('.ticks')
      .data(limits)
      .enter()
      .append('text')
      .attr('class', cx('ticks', this.ticksLimitsStyle))
      .attr('x', (d: Dataset, i: number) => i * this.x.bandwidth() + i * this.x.bandwidth() * this.xAxisPadding + tickExtraPaddingAxisX)
      .attr('y', height + tickPositionY)
      .style('text-anchor', 'middle')
      .text('\'');

    const positionY = this.typeStyles === typeStyles.DEFAULT ? 16 : 13;
    this.textLimits = svg.selectAll('.limits')
      .data(limits)
      .enter()
      .append('text')
      .attr('class', cx('limits', this.limitsStyle))
      .attr('x', (d: Dataset, i: number) => i * this.x.bandwidth() + i * this.x.bandwidth() * this.xAxisPadding)
      .attr('y', height + positionY)
      .style('text-anchor', 'middle')
      .text((d: number) => d);

    this.textFrequencies = svg.selectAll('.frequencies')
      .data(frequency.frequencies)
      .enter()
      .append('text')
      .attr('class', cx('frequencies', this.frequenciesStyles))
      .attr('x', (d: number, i: number) => {
        return i * this.x.bandwidth() + this.x.bandwidth() / 2 + i * this.x.bandwidth() * this.xAxisPadding;
      })
      .attr('y', (d: number) => this.y(d) - 5)
      .style('text-anchor', 'middle')
      .text((d: number) => d || '');

    if (this.histogram !== typeHistogram.PIGMENTATION && this.histogram !== typeHistogram.SURVIVAL_RATE) {
      svg.append('line')
        .attr('class', cx('averageLine', styles.averageLine))
        .attr('x1', this.xScaleLinear(average))
        .attr('y1', -20)
        .attr('x2', this.xScaleLinear(average))
        .attr('y2', height);

      svg.append('text')
        .attr('class', cx('averageText', this.averageTextStyle))
        .attr('x', this.xScaleLinear(average))
        .attr('y', -25)
        .style('text-anchor', 'middle')
        .text(`${i18next.t('detail.avg')} ${average} ${this.unit}`);
    }
  }

  refreshHistogram (dataset: Dataset[], histogram: string, limits: number[], frequency: Frequency, average: number, unit?: string) {
    const { container, svg, height, timeTransition } = this;

    this.dataset = dataset;
    this.histogram = histogram;
    this.limits = limits;
    this.frequency = frequency;
    this.average = average;
    this.unit = unit;

    this.buildAxisX();
    this.buildAxisY();

    this.bars = svg.selectAll('.histogram')
      .data(dataset);

    this.bars.enter()
      .append('rect')
      .merge(this.bars)
      .transition()
      .duration(timeTransition)
      .attr('class', 'histogram')
      .attr('x', (d: Dataset) => this.x(d.index?.toString()) as number)
      .attr('width', this.x.bandwidth())
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', (d: Dataset, index: number) => {
        if (this.histogram === typeHistogram.PIGMENTATION) {
          return colosPigmentation[index];
        }
        return this.fillBars;
      });

    this.bars.exit()
      .remove();

    const tickPositionY = this.typeStyles === typeStyles.DEFAULT ? 8 : 7;
    const tickExtraPaddingAxisX = this.typeStyles === typeStyles.DEFAULT ? 0.5 : 2.5;
    //update ticks limits
    this.ticksLimits = svg.selectAll('.ticks')
      .data(limits);

    this.ticksLimits.enter()
      .append('text')
      .merge(this.ticksLimits)
      .transition()
      .duration(timeTransition)
      .attr('class', cx('ticks', this.ticksLimitsStyle))
      .attr('x', (d: number, i: number) => i * this.x.bandwidth() + i * this.x.bandwidth() * this.xAxisPadding + tickExtraPaddingAxisX)
      .attr('y', height + tickPositionY)
      .style('text-anchor', 'middle')
      .text('\'');

    this.ticksLimits.exit()
      .remove();

    const positionY = this.typeStyles === typeStyles.DEFAULT ? 16 : 12;
    //update limits
    this.textLimits = svg.selectAll('.limits')
      .data(limits);

    this.textLimits.enter()
      .append('text')
      .merge(this.textLimits)
      .transition()
      .duration(timeTransition)
      .attr('class', cx('limits', this.limitsStyle))
      .attr('x', (d: number, i: number) => i * this.x.bandwidth() + i * this.x.bandwidth() * this.xAxisPadding)
      .attr('y', height + positionY)
      .style('text-anchor', 'middle')
      .text((d: number) => d);

    this.textLimits.exit()
      .remove();

    //update frequency
    this.textFrequencies = svg.selectAll('.frequencies')
      .data(frequency.frequencies);

    this.textFrequencies.enter()
      .append('text')
      .merge(this.textFrequencies)
      .transition()
      .duration(timeTransition)
      .attr('class', cx('frequencies', this.frequenciesStyles))
      .attr('x', (d: number, i: number) => i * this.x.bandwidth() + this.x.bandwidth() / 2 + i * this.x.bandwidth() * this.xAxisPadding)
      .attr('y', (d: number) => this.y(d) - 5)
      .style('text-anchor', 'middle')
      .text((d: number) => d || '');

    this.textFrequencies.exit()
      .remove();

    d3.select(container).selectAll('.averageLine').remove();
    d3.select(container).selectAll('.averageText').remove();

    if (this.histogram !== typeHistogram.PIGMENTATION && this.histogram !== typeHistogram.SURVIVAL_RATE) {
      svg.append('line')
        .attr('class', cx('averageLine', styles.averageLine))
        .attr('x1', this.xScaleLinear(average))
        .attr('y1', -20)
        .attr('x2', this.xScaleLinear(average))
        .attr('y2', height);

      svg.append('text')
        .attr('class', cx('averageText', this.averageTextStyle))
        .attr('x', this.xScaleLinear(average))
        .attr('y', -25)
        .style('text-anchor', 'middle')
        .text(`${i18next.t('detail.avg')} ${average} ${this.unit}`);
    }
  }

  buildAxisX () {
    const { dataset, limits, width } = this;

    this.x = d3.scaleBand()
      .domain(dataset.map(function (d) { return d.index.toString(); }))
      .range([0, width])
      .padding(this.xAxisPadding);

    this.xScaleLinear = d3.scaleLinear()
      .domain([limits[0], limits[limits.length - 1]])
      .range([0, width]);
  }

  buildAxisY () {
    const { dataset, height } = this;
    let maxDomain: number = d3.max(dataset, function (d) { return d.frequency; }) ?? 0;
    maxDomain = maxDomain <= 0 ? 10 : maxDomain;

    this.y = d3
      .scaleLinear()
      .domain([0, maxDomain])
      .range([height, 0]);
  }

  resize (width: number, height: number) {
    const { container, dataset, limits, frequency, average } = this;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#content').selectAll('*').remove();

    this.createSvgElement(this.width, this.height);
    this.createHistogram(dataset, limits, frequency, average);
  }
}

