import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';

import { Store } from '../../state/store.interface';
import PreviousNextButtons from './PreviousNextButtons';
import { STATIC_MAP_URL } from '../../config/config.api';
import { GOOGLE_MAP_API } from '../../config/google-maps';
import { getHeight, getWidth, handleResizeEvent } from '../../utils/dimensions';

import styles from './Map.module.scss';

function Map (props: { containerClassName?: string; notAvailableStyle?: string; showPreviousNextButtons?: boolean }) {
  const { containerClassName = styles.container, notAvailableStyle = styles.notAvailable, showPreviousNextButtons = true } = props;

  const [t] = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const refMap = useRef<HTMLDivElement>(null);
  const { allAnalysis, analysis: analysisOriginal, currentIndex } = useSelector((state: Store) => state.detail);
  const currentAnalysis = allAnalysis ? allAnalysis[currentIndex] : analysisOriginal;

  const latitude = currentAnalysis.imageData?.original?.location[0] ?? 0;
  const longitude = currentAnalysis.imageData?.original?.location[1] ?? 0;
  const existLocation = latitude !== 0 && longitude !== 0;

  useEffect(() => {
    handleResizeEvent(() => {
      const width = getWidth(refMap);
      const height = getHeight(refMap);
      setWidth(width);
      setHeight(height);
    });
  }, []);

  useEffect(updateScreenOnResize, [height]);

  function updateScreenOnResize () {
    if (refMap.current) {
      const width = getWidth(refMap);
      const height = getHeight(refMap);
      setWidth(width);
      setHeight(height);
    }
  }

  const renderPreviousNextButtons = () => {
    if (showPreviousNextButtons) {
      return (
        <PreviousNextButtons />
      );
    }

    return null;
  };

  if (!existLocation) {
    return (
      <div id='location_not_available' className={notAvailableStyle}>
        <div className={styles.center} ref={refMap} id={'map'} >
          {t('detail.mapNotAvailable')}
        </div>
        {renderPreviousNextButtons()}
      </div>
    );
  }

  function renderMap () {
    const url = `${STATIC_MAP_URL}?center=${latitude},${longitude}&zoom=17&size=${width}x${height}&maptype=hybrid&&markers=color:red%7C${latitude},${longitude}&key=${GOOGLE_MAP_API}`;
    return (
      <img
        id={'map'}
        src={url}
        alt={'Location sample'}
      />
    );
  }

  return (
    <div className={containerClassName}>
      <div ref={refMap} >
        {renderMap()}
      </div>
      {renderPreviousNextButtons()}
    </div>
  );
}

export default Map;
