import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './FullScreen.module.scss';
import Icon from '../../components/Icon/Icon';

export default function FullScreen (props: {
  children: ReactNode;
  title: string | ReactNode;
  show: boolean;
  onClose: Function;
}) {
  const { children, title, show, onClose } = props;
  if (!show) {
    return null;
  }
  return <div id='modal_fullscreen' className={cx(styles.fullScreenFrame)}>
    <div className={styles.header}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.close}>
        <div onClick={onClose as any} >
          <Icon id='close_fullscreen' name='close' />
        </div>
      </div>
    </div>
    <div className={styles.body}>
      {children}
    </div>
  </div>;
}
