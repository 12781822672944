import i18next from 'i18next';
import { roundWeight, stockingPhaseTypes } from '../../config/commons';

export const getAverageGrowthOrLarvaePerGram = (params: { phaseType: string; stage: number; averageWeight: number; larvaePerGram: number }) => {
  const { phaseType, averageWeight, stage, larvaePerGram } = params;

  if (phaseType === stockingPhaseTypes.ADULT) {
    return `${roundWeight({ value: averageWeight / (stage / 7), showUnit: false })} ${i18next.t('analysis.resultData.gramPerWeek')}`;
  }

  return `${larvaePerGram}`;
};

export const getAverageGrowthOrLarvaePerGramLabel = (phaseType: string) => {
  if (phaseType === stockingPhaseTypes.LARVAE) {
    return i18next.t('analysis.resultData.larvaePerGram');
  }
  if (phaseType === stockingPhaseTypes.ADULT) {
    return i18next.t('analysis.resultData.avgGrowth');
  }
  return i18next.t('analysis.resultData.juvenilePerGram');
};