export const environments = {
  DEV: 'dev',
  STG: 'stg',
  PROD: 'prod'
};

export const analysisStatuses = {
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
};

export const typeHistogram = {
  WEIGHT: 'WEIGHT',
  LENGTH: 'LENGTH',
  PIGMENTATION: 'PIGMENTATION',
  SURVIVAL_RATE: 'SURVIVAL_RATE',
};

export const analysisTypes = {
  GENERAL_LARVAE_ANALYSIS: 'GENERAL_LARVAE_ANALYSIS',
  GENERAL_JUVENILE_ANALYSIS: 'GENERAL_JUVENILE_ANALYSIS',
  GENERAL_ADULT_ANALYSIS: 'GENERAL_ADULT_ANALYSIS',
  CONSOLIDATED_JUVENILE_ANALYSIS: 'CONSOLIDATED_JUVENILE_ANALYSIS',
  CONSOLIDATED_ADULT_ANALYSIS: 'CONSOLIDATED_ADULT_ANALYSIS',
};

export const stockingPhaseTypes = {
  LARVAE: 'LARVAE',
  JUVENILE: 'JUVENILE',
  ADULT: 'ADULT',
};

export const weightUnits = {
  G: 'g',
  MG: 'mg'
};

export const lengthUnits = {
  CM: 'cm',
  MM: 'mm'
};

export const animalDaysUnit = {
  PL: 'PL',
  D: 'D'
};

export const roundWeight = (params: { value: number | number[]; showUnit?: boolean; average?: number }) => {
  const { value, showUnit = true, average = 0 } = params;

  const minValue = 100;
  const constantToConvert = 1000;
  const isArray = Array.isArray(value);

  if (isArray) {
    const arrayValue: any = value;
    if (average < minValue) {
      return arrayValue;
    }

    return arrayValue.map((weight: number) => roundTwoDecimals(weight / constantToConvert));
  } else {
    const numberValue: any = value;
    if (value >= minValue) {
      const number = roundTwoDecimals(numberValue / constantToConvert);
      return showUnit ? `${number} ${weightUnits.G}` : number;
    }

    return showUnit ? `${roundTwoDecimals(numberValue)} ${weightUnits.MG}` : roundTwoDecimals(numberValue);
  }
};

export const getWeightUnit = (average: number) => {
  if (average === 0) {
    return '';
  }

  const minValue = 100;

  if (average < minValue) {
    return weightUnits.MG;
  }

  return weightUnits.G;
};

export const roundLength = (params: { value: number | number[]; showUnit?: boolean; average?: number }) => {
  const { value, showUnit = true, average = 0 } = params;
  const minValue = 10;
  const constantToConvert = 10;
  const isArray = Array.isArray(value);

  if (isArray) {
    const arrayValue: any = value;
    if (average < minValue) {
      return arrayValue;
    }

    return arrayValue.map((length: number) => roundTwoDecimals(length / constantToConvert));

  } else {
    const numberValue: any = value;
    if (value >= minValue) {
      const number = roundTwoDecimals(numberValue / constantToConvert);
      return showUnit ? `${number} ${lengthUnits.CM}` : number;
    }

    return showUnit ? `${roundTwoDecimals(numberValue)} ${lengthUnits.MM}` : roundTwoDecimals(numberValue);

  }
};

export const getLengthUnit = (average: number) => {
  const minValue = 10;

  if (average < minValue) {
    return lengthUnits.MM;
  }

  return lengthUnits.CM;
};

export const roundOneDecimal = (value: number) => {
  return Math.round((value + Number.EPSILON) * 10) / 10;
};

export const roundTwoDecimals = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const DEFAULT_DAYS_TO_INITIAL_STAGE = 7;
