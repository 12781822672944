import { Coordinate, WeightGroups, Percentile, Larvae, AnimalsByGroup } from './interfaces';

export function groupLabels (labels: number[] | number[]) {
  const labelsGroup: string[] = [];

  let rank: string;
  for (let i = 0; i < labels.length; i++) {
    if (i === 0) {
      rank = `< ${labels[i + 1]}`;
    } else if (i === labels.length - 1) {
      rank = `> ${labels[i]}`;
    } else {
      if (i === 1) {
        rank = labels[i].toFixed(2) + ` - ${labels[i + 1]}`;
      } else {
        rank = (labels[i] + 0.01).toFixed(2) + ` - ${labels[i + 1]}`;
      }
    }

    labelsGroup.push(rank);
  }
  return labelsGroup;
}

export function createListPercentiles (
  props: { labelsSort: string[]; countGroup: number[]; colors: string[]; activePercentile: boolean[] }) {
  const { labelsSort, countGroup, colors, activePercentile } = props;
  const listPercentile: Percentile[] = [];

  for (let i = 0; i < labelsSort.length; i++) {
    const percentile: Percentile = {
      isActive: activePercentile[i],
      rank: labelsSort[i],
      value: `${countGroup[i]}`,
      color: colors[i],
      listLarvae: [],
      group: i + 1,
    };
    listPercentile.push(percentile);
  }
  return listPercentile;
}

function _groupAnimals (props: { animals: Coordinate[]; listPercentile: Percentile[]; averageArea: number; percentileGroup: number; radius: number }) {
  const { animals, listPercentile, averageArea, radius, percentileGroup } = props;

  animals.forEach(animal => {
    const point = [animal.x * 1.0, animal.y * 1.0];

    const larvae: Larvae = {
      point: point,
      area: averageArea,
      radius: radius,
      added: animal.added,
      removed: animal.removed,
      previousGroup: animal.previousGroup,
    };
    listPercentile[percentileGroup].listLarvae.push(larvae);
  });

  return listPercentile;
}

export function groupAnimalsOld (props: { listPercentile: Percentile[]; areaPercentIncrease: number; weightGroups: WeightGroups }) {
  const { areaPercentIncrease, weightGroups } = props;
  let { listPercentile } = props;

  Object.entries(weightGroups).forEach(
    ([key, value]) => {
      const percentileGroup = parseInt(key) - 1;
      const animals: Coordinate[] = value.animals;
      const avgArea = increaseArea(value.area, areaPercentIncrease);
      const diameter = avgArea / Math.PI;
      const radius = diameter / 2;

      listPercentile = _groupAnimals({ animals, listPercentile, averageArea: avgArea, percentileGroup, radius });
    }
  );

  return listPercentile;
}

export function groupAnimals (props: { listPercentile: Percentile[]; pointSizeRatio: number; weightGroups: WeightGroups; imageSize: number }) {
  const { pointSizeRatio, weightGroups, imageSize } = props;
  let { listPercentile } = props;

  Object.entries(weightGroups).forEach(
    ([key, value]) => {
      const percentileGroup = parseInt(key) - 1;
      const animals: Coordinate[] = value.animals;
      const avgArea = pointSizeRatio * value.area * imageSize * 100;
      const diameter = Math.sqrt(avgArea / Math.PI);
      const radius = diameter / 2;

      listPercentile = _groupAnimals({ animals, listPercentile, averageArea: avgArea, percentileGroup, radius });
    }
  );

  return listPercentile;
}

export function createListAnimalsByGroup (props: { colors: string[] }) {
  const { colors } = props;
  const animalsByGroup: AnimalsByGroup[] = [];

  for (let i = 0; i < colors.length; i++) {
    const percentile: AnimalsByGroup = {
      isActive: true,
      color: colors[i],
      listLarvae: [],
      group: i + 1,
    };
    animalsByGroup.push(percentile);
  }
  return animalsByGroup;
}

function _fillAnimalsByGroup (props: { animals: Coordinate[]; animalsByGroup: AnimalsByGroup[]; averageArea: number; percentileGroup: number; radius: number }) {
  const { animals, animalsByGroup, averageArea, radius, percentileGroup } = props;

  animals.forEach(animal => {
    const point = [animal.x * 1.0, animal.y * 1.0];

    const larvae: Larvae = {
      point: point,
      area: averageArea,
      radius: radius,
      added: animal.added,
      removed: animal.removed,
      previousGroup: animal.previousGroup,
    };
    animalsByGroup[percentileGroup].listLarvae.push(larvae);
  });

  return animalsByGroup;
}

export function fillAnimalsByGroupOld (props: { animalsByGroup: AnimalsByGroup[]; areaPercentIncrease: number; weightGroups: WeightGroups }) {
  const { areaPercentIncrease, weightGroups } = props;
  let { animalsByGroup } = props;

  Object.entries(weightGroups).forEach(
    ([key, value]) => {
      const percentileGroup = parseInt(key) - 1;
      const animals: Coordinate[] = value.animals;
      const avgArea = increaseArea(value.area, areaPercentIncrease);
      const diameter = avgArea / Math.PI;
      const radius = diameter / 2 * 0.5;

      animalsByGroup = _fillAnimalsByGroup({ animals, animalsByGroup, averageArea: avgArea, percentileGroup, radius });
    }
  );

  return animalsByGroup;
}

export function fillAnimalsByGroup (props: { animalsByGroup: AnimalsByGroup[]; pointSizeRatio: number; weightGroups: WeightGroups; imageSize: number }) {
  const { pointSizeRatio, weightGroups, imageSize } = props;
  let { animalsByGroup } = props;

  Object.entries(weightGroups).forEach(
    ([key, value]) => {
      const percentileGroup = parseInt(key) - 1;
      const animals: Coordinate[] = value.animals;
      const avgArea = pointSizeRatio * value.area * imageSize * 100;
      const diameter = Math.sqrt(avgArea / Math.PI);
      const radius = diameter / 2 * 0.5;

      animalsByGroup = _fillAnimalsByGroup({ animals, animalsByGroup, averageArea: avgArea, percentileGroup, radius });
    }
  );

  return animalsByGroup;
}

function increaseArea (area: number, percentIncrease: number) {
  const increase = (area * percentIncrease) / 100;
  return area + increase;
}
