import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { history } from './helpers/history';
import DetailAnalysis from './pages/Analysis-Detail/DetailAnalysis';

function App () {
  const defaultPath = '/analysis/:id/:token';

  return (
    <Router history={history}>
      <Switch>

        <Route exact path='/'>
          <Redirect to={defaultPath} />
        </Route>
        <Route path={defaultPath} component={DetailAnalysis} />
        <Redirect from='*' to='/' />
      </Switch>
    </Router>
  );
}

export default App;
