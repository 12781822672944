import { useSelector } from 'react-redux';

import larviaLogoSvg from '../../assets/larvia.svg';
import { Store } from '../../state/store.interface';

import './AppHeader.scss';
import styles from './AppHeader.module.scss';

export default function AppHeader () {
  const detailAnalysis = useSelector((state: Store) => state.detail.analysis);

  if (!detailAnalysis._id) {
    return null;
  }

  return (
    <div className={styles.header}>
      <div className={styles.leftHeader}>
        <div className={styles.titlePublic}>
          {detailAnalysis.code}
        </div>
      </div>

      <div className={styles.rightHeader}>
        <img src={larviaLogoSvg} height={50} alt='Larvia logo' />
      </div>
    </div>
  );
}
