import cx from 'classnames';

import './Icon.scss';
import styles from './Icon.module.scss';

interface Props {
  id?: string;
  name: string;
  type?: string;
  className?: string;
  spin?: boolean;
  spy?: boolean;
}

export default function Icon (props: Props) {
  const { id, name, type = 'line', className, spin, spy } = props;
  return <i id={id} className={cx(`ri-${name}-${type}`, styles.lrvIcon, className, spin ? styles.spin : '', spy ? styles.spy : '')} />;
}
