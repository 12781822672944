import cx from 'classnames';
import { Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { Store } from '../../state/store.interface';
import Icon from '../../common/components/Icon/Icon';

import { isConsolidatedAnalysis } from './helpers';
import styles from './PreviousNextButtons.module.scss';
import { setCurrentIndex } from './detailAnalysisSlice';

export default function PreviousNextButtons () {
  const dispatch = useDispatch();

  const { allAnalysis, analysis: analysisOriginal, currentIndex } = useSelector((state: Store) => state.detail);
  const analysisType = analysisOriginal.type;

  if (!isConsolidatedAnalysis(analysisType)) {
    return null;
  }

  const disabledPreviousButton = () => {
    return currentIndex === 0;
  };

  const disabledNextButton = () => {
    return allAnalysis?.length !== undefined && currentIndex === (allAnalysis.length - 1);
  };

  return (
    <Radio.Group className={styles.previousNextButtons} size='small'>
      <Radio.Button
        className={cx(styles.leftButton, disabledPreviousButton() ? styles.disabledButton : '')}
        onClick={() => dispatch(setCurrentIndex(currentIndex - 1))}
        disabled={disabledPreviousButton()}
      >
        <Icon name='arrow-left-s' className={cx(styles.icon, disabledPreviousButton() ? styles.disabledIcon : '')} />
      </Radio.Button>

      <Radio.Button
        className={cx(styles.rightButton, disabledNextButton() ? styles.disabledButton : '')}
        onClick={() => dispatch(setCurrentIndex(currentIndex + 1))}
        disabled={disabledNextButton()}
      >
        <Icon name='arrow-right-s' className={cx(styles.icon, disabledNextButton() ? styles.disabledIcon : '')} />
      </Radio.Button>

    </Radio.Group>
  );
}
