export function handleResizeEvent (callback: Function) {
  let resizeTimer: any;

  const handleResize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(callback, 300);
  };
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}

export function getWidth (ref: React.RefObject<HTMLDivElement>) {
  if (ref.current && ref.current.parentElement) {
    return ref.current.parentElement.offsetWidth;
  }
  return 1000;
}

export function getHeight (ref: React.RefObject<HTMLDivElement>) {
  if (ref.current && ref.current.parentElement) {
    return ref.current.parentElement.offsetHeight;
  }
  return 1000;
}
