import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-au';

import { getLanguage } from './language';

export const formatLocaleDate = (date: string, hoursOffset = 0) => {
  const language = getLanguage();
  moment.locale(language);

  if (language === 'es') {
    let month = moment(date).subtract(hoursOffset, 'h').format('MMM').replace('.', ',');
    month = month.charAt(0).toUpperCase() + month.slice(1);
    const day = moment(date).subtract(hoursOffset, 'h').format('D');
    const year = moment(date).subtract(hoursOffset, 'h').format('YYYY');

    return `${day} ${month} ${year}`;
  }

  return moment(date).subtract(hoursOffset, 'h').format('ll');
};

export const formatLocaleHour = (date: string, hoursOffset = 0) => {
  return moment(date).subtract(hoursOffset, 'h').format('HH:mm');
};
